import React from 'react';
import { EMPTY_DOCUMENT } from '@contentful/rich-text-types';

import { IMAGE_ASPECT_RATIO, IPrimaryBanner } from '@belong/types';
import { FONT_COLOURS } from '@belong/themes';
import { useAnalyticsEvents } from '@belong/providers/analyticsEvents';
import { buttonClick } from '@belong/analytics';
import { isExternalUrl } from '@belong/url-utils';

import ContentFormatter from '../../styles/ContentFormatter';
import { Copy, Display } from '../../styles/Typography';
import { NODES as defaultRenderers, RichText } from '../../styles/Typography/RichText/RichText';
import { TEXT_NODES } from '../../styles/Typography/RichText/RichText.types';
import { Button } from '../../actions/Button';
import * as Banner from './PrimaryBanner.styles';
import Callout from '../../feedback/Callout';
import { IRoamingCountriesProps, RoamingCountries } from '../RoamingCountries';
import { FRoamingCountries } from './countryList.factory';

export const PrimaryBanner: React.FC<IPrimaryBanner> = ({
  id,
  identifier,
  link,
  heading,
  copy,
  content = EMPTY_DOCUMENT,
  hideImageOnSmall = false,
  image,
  callout,
  video,
  leftAlignBanner = false,
  layout,
  variation = 'large',
  aspectRatio = IMAGE_ASPECT_RATIO.letterbox
}) => {
  let media;

  if (video) {
    media = (
      <Banner.VideoWrapper variation={variation} hideImageOnSmall={hideImageOnSmall} ratio={aspectRatio}>
        <Banner.StyledVideoContainer {...video} />
      </Banner.VideoWrapper>
    );
  } else {
    media = (
      <Banner.ImageWrapper variation={variation} hideImageOnSmall={hideImageOnSmall} ratio={aspectRatio}>
        <Banner.Image
          leftAlign={leftAlignBanner}
          loading={'eager'}
          src={{
            xs: [image.src, { w: 360, h: 260, fit: 'fill', f: 'face' }],
            sm: [image.src, { w: 800, h: 600, fit: 'fill', f: 'face' }],
            md: [image.src, { w: 520, h: 390, fit: 'fill', f: 'face' }],
            lg: [image.src, { w: 720, h: 510, fit: 'fill', f: 'face' }],
            xl: [image.src, { w: 1100, h: 510, fit: 'fill', f: 'face' }]
          }}
          alt={image.alt}
          contentType={image.contentType}
        />
      </Banner.ImageWrapper>
    );
  }

  const analyticsEvents = useAnalyticsEvents();
  const onClickHandler = () => {
    analyticsEvents.send({
      ...buttonClick({
        label: (link?.label || 'primary-banner-link').toLowerCase().replace(/\s/g, '-'),
        text: link?.label,
        destination: isExternalUrl(link?.href) ? 'external' : 'internal'
      }),
      component: 'primary-banner'
    });
  };

  return (
    <Banner.OuterWrapper
      data-contentid={id}
      data-identifier={identifier}
      data-testid="section-primary-banner"
      layout={layout}
    >
      <Banner.ContentWrapper layout={layout}>
        {callout && (
          <Banner.DesktopOnly>
            <Banner.CalloutWrapper>
              <Callout {...callout} data-testid="primary-banner-callout-desktop" />
            </Banner.CalloutWrapper>
          </Banner.DesktopOnly>
        )}
        <ContentFormatter spacing="large">
          {heading && copy ? (
            <>
              <Display hasColor={FONT_COLOURS.LIGHT}>{heading}</Display>
              <Copy variant="large" hasColor={FONT_COLOURS.LIGHT}>
                {copy}
              </Copy>
            </>
          ) : (
            <RichText
              html={content}
              p={TEXT_NODES.copyLarge}
              hasColor={FONT_COLOURS.LIGHT}
              renderBlockEntries={{
                countryList: entryProps => {
                  const props: IRoamingCountriesProps = FRoamingCountries(entryProps?.data?.target || {});
                  return <RoamingCountries {...props} as="div" />;
                }
              }}
              renderEmbeddedInlines={{
                elementEmbeddedInformationModal: entryProps => {
                  return defaultRenderers.elementEmbeddedInformationModal(entryProps, {
                    isLightColor: true,
                    isSmallFontSize: true
                  });
                }
              }}
            />
          )}
        </ContentFormatter>
        {link && (
          <Banner.ButtonWrapper>
            <Button isLightColor {...link} onClick={onClickHandler} width={{ xs: 'full', md: 'default' }}>
              {link.children || link.label}
            </Button>
          </Banner.ButtonWrapper>
        )}
      </Banner.ContentWrapper>
      {media}
      {callout && (
        <Banner.MobileOnly>
          <Banner.ContentWrapper layout={layout} style={{ paddingBottom: 0 }}>
            <Banner.CalloutWrapper>
              <Callout {...callout} data-testid="primary-banner-callout-mobile" />
            </Banner.CalloutWrapper>
          </Banner.ContentWrapper>
        </Banner.MobileOnly>
      )}
    </Banner.OuterWrapper>
  );
};

PrimaryBanner.displayName = 'PrimaryBanner';

export default PrimaryBanner;
